<template>
  <div class="login">
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <div class="m-card text-center">
          <h1 class="o-heading o-heading--xl">{{ $t("login.login") }}</h1>
          <p>
            {{ $t("login.message_0") }}
          </p>
        </div>
      </div>
      <div class="col-10 offset-1">
        <app-login></app-login>
      </div>
    </div>
  </div>
</template>
<script>
import AppLogin from "../components/organisms/AppLogin.vue";

export default {
  components: { AppLogin },
  name: "login",

  data() {
    return {
      message: "",
    };
  },

  methods: {},
};
</script>

<template>
  <div class="o-login">

    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="o-login__form ">
      <b-alert variant="danger" id="errore_generico" v-model="showErroreGenerico">{{ $t("userDetalies.errore_generico")
        }}</b-alert>
      <b-alert variant="danger" id="password_errata" v-model="showPasswordErrata">{{ $t("userDetalies.password_errata")
      }}</b-alert>
      <b-alert variant="danger" id="utente_bloccato" v-model="showUtenteBloccato">{{ $t("userDetalies.utente_bloccato")
      }}</b-alert>
      <b-alert variant="danger" id="utente_inattivo" v-model="showUtenteInnativo">{{ $t("userDetalies.utente_inattivo")
      }}</b-alert>
      <b-alert variant="danger" id="utente_disabilitato" v-model="showUtenteDisabilitato">{{
          $t("userDetalies.utente_disabilitato")
      }}</b-alert>
      <b-form-group id="nameCF" label="Nome utente o Codice Fiscale:" label-for="nameCF-input" class="m-input">
        <b-form-input id="nameCF-input" v-model="user.username" type="text" placeholder="Nome utente o Codice Fiscale"
          required></b-form-input>
      </b-form-group>
      <b-form-group id="password" label="Password" label-for="password-input" class="m-input">
        <b-form-input id="password-input" v-model="user.password" type="password" placeholder="Password" required>
        </b-form-input>
      </b-form-group>
      <div class="row no-gutters">
        <div class="col-md-5 mt-3">
          <b-button type="submit" variant="primary">{{
              $t("login.login")
          }}</b-button>
        </div>
        <div class="col-md-7 mt-3">
          <a href="forgotPassword" class="text-primary"><strong>{{ $t("login.message_1") }}</strong></a><br />
          <a href="riattivaUtente" class="text-primary"><strong>{{ $t("login.message_2") }}</strong></a>
        </div>
      </div>
    </b-form>
    <div class="border-top text-center pt-4">
      <p class="o-heading o-heading--xl">{{ $t("login.message_3") }}</p>
      <p>{{ $t("login.message_4") }}</p>
      <b-button :to="{ path: '/registrazione' }" variant="secondary" class="text-light text-uppercase">{{
          $t("login.action_0")
      }}
      </b-button>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AppLogin",

  data() {
    return {
      user: {
        username: "",
        password: "",
      },

      state: {
        code: null,
        message: null,
        token: null,
        logged: null,
      },
      showPasswordErrata: false,
      showUtenteBloccato: false,
      showUtenteInnativo: false,
      showUtenteDisabilitato: false,
      showErroreGenerico: false,

      show: true,
    };
  },

  computed: {
    prenotingUrl() {
      return process.env.VUE_APP_PRENOTING_URL;
    },
    idPrenoting() {
      return this.$route.query.idprenoting;
    }
  },


  methods: {
    async onSubmit(event) {
      event.preventDefault();
      store.dispatch("setLoading", true);
      console.log('test')
      try {
        await this.$store.dispatch("login", this.user).then(() => {
          this.state = store.getters["getState"];
          if (sessionStorage.getItem("token") !== null) {
            if (
              sessionStorage.getItem("SET_LOGGED") ===
              sessionStorage.getItem("token")
            ) {
              console.log("login OK");
              this.loadUserData();
            } else {
              store.dispatch("setLoading", false);
              this.checkState(this.state);
            }
          } else {
            console.log("Credenziali errate");
            store.dispatch("setLoading", false);
          }
        });
      } catch (e) {
        console.log({ message: "Some error login" });
        store.dispatch("setLoading", false);
      }
    },

    async loadUserData() {
      await store.dispatch("dateUser/loadUser").then(() => {
        console.log("Userdata OK:" + JSON.stringify(store.getters['dateUser/UserData']));

        const userdata = store.getters['dateUser/UserData'];
        if (this.idPrenoting != null) {
          let token = null;
          if (userdata.services != null) {
            for (let i = 0; i < userdata.services.length; i++) {
              if (userdata.services[i].authentication == 'TOKEN') {
                token = userdata.services[i].token;
                break;
              }
            }
          }

          window.location = this.prenotingUrl + "sso?token=" + token + '&id=' + this.idPrenoting;
          return;
        }
        console.log(this.$route.query.nextUrl);

        if (typeof (this.$route.query.nextUrl) !== 'undefined' || this.$route.query.nextUrl !== undefined) {
          this.$router.push(this.$route.query.nextUrl);
        } else {
          this.$router.push("/")
        }
      });

    },

    checkState(payload) {
      console.log(payload);
      if (payload.code === 0) {
        this.showPasswordErrata = false;
        this.showUtenteBloccato = false;
        this.showUtenteInnativo = false;
        this.showUtenteDisabilitato = false;
        this.showErroreGenerico=false;
      } else if (payload.code === -2) {
        this.showPasswordErrata = false;
        this.showUtenteBloccato = false;
        this.showUtenteInnativo = false;
        this.showErroreGenerico=false;
        this.showUtenteDisabilitato = false;
      } else if (payload.code === -3) {
        this.showPasswordErrata = false;
        this.showUtenteBloccato = true;
        this.showUtenteInnativo = false;
        this.showUtenteDisabilitato = false;
        this.showErroreGenerico=false;
      } else if (payload.code === -4) {
        this.showPasswordErrata = true;
        this.showUtenteBloccato = false;
        this.showUtenteInnativo = false;
        this.showUtenteDisabilitato = false;
        this.showErroreGenerico=false;
      } else if (payload.code === -5) {
        this.showPasswordErrata = false;
        this.showUtenteBloccato = false;
        this.showUtenteInnativo = true;
        this.showUtenteDisabilitato = false;
        this.showErroreGenerico=false;
      } else if (payload.code === -6) {
        this.showPasswordErrata = false;
        this.showUtenteBloccato = false;
        this.showUtenteInnativo = true;
        this.showUtenteDisabilitato = false;
        this.showErroreGenerico=false;
      } else if (payload.code === -7) {
        this.showPasswordErrata = false;
        this.showUtenteBloccato = false;
        this.showUtenteInnativo = false;
        this.showUtenteDisabilitato = true;
        this.showErroreGenerico=false;
      }  else if (payload.code === -99) {
        this.showPasswordErrata = false;
        this.showUtenteBloccato = false;
        this.showUtenteInnativo = false;
        this.showUtenteDisabilitato = false;
        this.showErroreGenerico=true;
      }

    },

    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.nameCF = "";
      this.form.password = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  }
};
</script>
